export const T_ADDRESS = '0x6967299e9F3d5312740Aa61dEe6E9ea658958e31'
export const T1BETS_ADDRESS = '0xc7c8ff53359d8c76c1fbf83a995fa5456d55c7ed'

export const MATCHES = [
  {
    index: 0,
    category: 'soccer',
    description: 'World Cup Finals',
    timestamp: 1671375600,
    odds: [1.92, 1.93, 0],
    result: 0,
    data: [
      {
        name: 'Argentina',
        image:
          'https://rm3fpp0mqm3821.sptpub-infra.com/3f5e7a00/flags_by_code/ARG.svg',
      },
      {
        name: 'France',
        image:
          'https://rm3fpp0mqm3821.sptpub-infra.com/3f5e7a00/flags_by_code/FRA.svg',
      },
    ],
    minBetAmount: 100.0,
    maxBetAmount: 10000.0,
  },
  {
    index: 1,
    category: 'soccer',
    description: 'England - Premier League',
    timestamp: 1672057800,
    odds: [3.95, 1.93, 3.7],
    result: 2,
    data: [
      {
        name: 'Brentford FC',
        image:
          'https://static.sptpub.com/competitors/images/normal/medium/50.png',
      },
      {
        name: 'Tottenham Hotspur',
        image:
          'https://static.sptpub.com/competitors/images/normal/medium/33.png',
      },
    ],
    minBetAmount: 100.0,
    maxBetAmount: 10000.0,
  },
  {
    index: 2,
    category: 'soccer',
    description: 'England - Premier League',
    timestamp: 1672075800,
    odds: [4.5, 1.74, 4.1],
    result: 1,
    data: [
      {
        name: 'Aston Villa',
        image:
          'https://static.sptpub.com/competitors/images/normal/medium/40.png',
      },
      {
        name: 'Liverpool FC',
        image:
          'https://static.sptpub.com/competitors/images/normal/medium/44.png',
      },
    ],
    minBetAmount: 100.0,
    maxBetAmount: 10000.0,
  },
  {
    index: 3,
    category: 'basketball',
    description: 'NBA',
    timestamp: 1671580800,
    odds: [2.22, 1.69, 0],
    result: 1,
    data: [
      {
        name: 'Detroit Pistons',
        image:
          'https://static.sptpub.com/competitors/images/normal/medium/3424.png',
      },
      {
        name: 'Utah Jazz',
        image:
          'https://static.sptpub.com/competitors/images/normal/medium/3434.png',
      },
    ],
    minBetAmount: 100.0,
    maxBetAmount: 10000.0,
  },
  {
    index: 4,
    category: 'basketball',
    description: 'NBA',
    timestamp: 1671582600,
    odds: [1.52, 2.6, 0],
    result: 1,
    data: [
      {
        name: 'Miami Heat',
        image:
          'https://static.sptpub.com/competitors/images/normal/medium/3435.png',
      },
      {
        name: 'Chicago Bulls',
        image:
          'https://static.sptpub.com/competitors/images/normal/medium/3409.png',
      },
    ],
    minBetAmount: 100.0,
    maxBetAmount: 10000.0,
  },
  {
    index: 5,
    category: 'basketball',
    description: 'NBA',
    timestamp: 1671582600,
    odds: [1.5, 2.65, 0],
    result: 0,
    data: [
      {
        name: 'New York Knicks',
        image:
          'https://static.sptpub.com/competitors/images/normal/medium/3421.png',
      },
      {
        name: 'Golden State Warriors',
        image:
          'https://static.sptpub.com/competitors/images/normal/medium/3428.png',
      },
    ],
    minBetAmount: 100.0,
    maxBetAmount: 10000.0,
  },
  {
    index: 6,
    category: 'basketball',
    description: 'NBA',
    timestamp: 1671588000,
    odds: [1.34, 3.15, 0],
    result: 1,
    data: [
      {
        name: 'Phoenix Suns',
        image:
          'https://static.sptpub.com/competitors/images/normal/medium/3416.png',
      },
      {
        name: 'Washington Wizards',
        image:
          'https://static.sptpub.com/competitors/images/normal/medium/3431.png',
      },
    ],
    minBetAmount: 100.0,
    maxBetAmount: 10000.0,
  },
  {
    index: 7,
    category: 'basketball',
    description: 'NBA',
    timestamp: 1671591600,
    odds: [2.01, 1.78, 0],
    result: 0,
    data: [
      {
        name: 'Denver Nuggets',
        image:
          'https://static.sptpub.com/competitors/images/normal/medium/3417.png',
      },
      {
        name: 'Memphis Grizzlies',
        image:
          'https://static.sptpub.com/competitors/images/normal/medium/3415.png',
      },
    ],
    minBetAmount: 100.0,
    maxBetAmount: 10000.0,
  },
  {
    index: 8,
    category: 'soccer',
    description: 'England - Premier League',
    timestamp: 1673699400,
    odds: [4.4, 1.79, 3.95],
    result: null,
    data: [
      {
        name: 'Manchester United FC',
        image: 'https://img-cdn001.akamaized.net/ls/crest/medium/35.png',
      },
      {
        name: 'Manchester City FC',
        image: 'https://img-cdn001.akamaized.net/ls/crest/medium/17.png',
      },
    ],
    minBetAmount: 100.0,
    maxBetAmount: 10000.0,
  },
  {
    index: 9,
    category: 'soccer',
    description: 'England - Premier League',
    timestamp: 1673800200,
    odds: [3.4, 2.13, 3.6],
    result: null,
    data: [
      {
        name: 'Tottenham Hotspur',
        image: 'https://static.sptpub.com/competitors/images/normal/medium/33.png',
      },
      {
        name: 'Arsenal FC',
        image: 'https://static.sptpub.com/competitors/images/normal/medium/42.png',
      },
    ],
    minBetAmount: 100.0,
    maxBetAmount: 10000.0,
  },
  {
    index: 10,
    category: 'soccer',
    description: 'England - Premier League',
    timestamp: 1674304200,
    odds: [1.78, 4.4, 4.0],
    result: null,
    data: [
      {
        name: 'Liverpool FC',
        image: 'https://img-cdn001.akamaized.net/ls/crest/medium/44.png',
      },
      {
        name: 'Chelsea FC',
        image: 'https://img-cdn001.akamaized.net/ls/crest/medium/38.png',
      },
    ],
    minBetAmount: 100.0,
    maxBetAmount: 10000.0,
  },
  {
    index: 11,
    category: 'soccer',
    description: 'England - Premier League',
    timestamp: 1674405000,
    odds: [2, 3.7, 3.7],
    result: null,
    data: [
      {
        name: 'Arsenal FC',
        image: 'https://img-cdn001.akamaized.net/ls/crest/medium/42.png',
      },
      {
        name: 'Manchester United FC',
        image: 'https://img-cdn001.akamaized.net/ls/crest/medium/35.png',
      },
    ],
    minBetAmount: 100.0,
    maxBetAmount: 10000.0,
  },
]
