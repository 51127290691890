import React, { useState } from 'react'
import cn from 'classnames'
import { utils } from 'ethers'
import styles from './Item.module.sass'
import { MATCHES } from '../../../../utils/constants'

const Item = ({ item, children }) => {
  const [visible, setVisible] = useState(false)

  return (
    <>
      <div className={cn(styles.item, { [styles.active]: false })}>
        <div className={styles.row} onClick={() => setVisible(!visible)}>
          <div className={styles.col}>
            <div className={styles.currency}>
              { Number(item.outcome.toString()) - 1 === 2 ? null :
                <div className={styles.icon}>
                  <img
                      src={
                        MATCHES[Number(item.gameId.toString())].data[
                        Number(item.outcome.toString()) - 1
                            ].image
                      }
                      alt="Team"
                  />
                </div>
              }
              <div className={styles.details}>
                <div className={styles.info}>
                  {
                    MATCHES[Number(item.gameId.toString())].data[
                      Number(item.outcome.toString()) - 1
                    ]?.name ?? 'Draw'
                  }
                </div>
                <div className={styles.text}>{`${item.creator
                  .toString()
                  .slice(0, 6)}...${item.creator.toString().slice(-4)}`}</div>
              </div>
            </div>
          </div>
          <div className={styles.col}>
            <div className={styles.info}>
              {Number(utils.formatEther(item.amount)).toLocaleString('en-US', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }) + ' T'}
            </div>
            <div className={styles.text}>{item.priceTotal}</div>
          </div>
          {/* <div className={styles.col}>
            <div className={styles.info}>{item.currencyAvailable}</div>
            <div className={styles.text}>{item.priceAvailable}</div>
          </div>
          <div className={styles.col}>
            <div className={styles.info}>{item.currencyInterest}</div>
            <div className={styles.text}>{item.priceInterest}</div>
          </div> */}
        </div>
        <div className={styles.btns}>
          {/* <button
            className={cn('button-stroke button-small', styles.button)}
            disabled
          >
            Claim
          </button> */}
        </div>
      </div>
    </>
  )
}

export default Item
