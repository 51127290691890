import React, { useState } from 'react'
import cn from 'classnames'
import { NavLink, Link, useLocation } from 'react-router-dom'
import { useConnect, useAccount, useDisconnect } from 'wagmi'

import styles from './Wallet.module.sass'
import Icon from '../Icon'
import Modal from '../Modal'
import Withdraw from '../Withdraw'
import Transfer from '../Transfer'

const navigation = [
  {
    title: 'Dashboard',
    color: '#23262F',
    url: '/',
  },
  {
    title: 'Basketball',
    category: 'basketball',
    color: '#FF6838',
    url: '/basketball',
  },
  {
    title: 'Soccer',
    category: 'soccer',
    color: '#3772FF',
    url: '/soccer',
  },
  // {
  //   title: 'My Bets',
  //   color: '#FFD166',
  //   url: '/my-bets',
  //   // separator: true,
  // },
  {
    title: 'Vote',
    color: '#eaea',
    url: 'https://vote.t1bets.gg/',
    external: true,
    // separator: true,
  },
  {
    title: 'Contract',
    color: '#eeeeee',
    url: 'https://etherscan.io/address/0xc7c8ff53359d8c76c1fbf83a995fa5456d55c7ed',
    external: true,
    // separator: true,
  },
  // {
  //   title: 'Transfer',
  //   icon: 'arrow-next',
  // },
  // {
  //   title: 'Buy with Fiat',
  //   icon: 'wallet',
  //   url: '/buy-crypto',
  // },
  // {
  //   title: 'Sell for Fitat',
  //   icon: 'sell',
  //   url: '/sell-crypto',
  // },
]

const Wallet = ({ category, className, children }) => {
  const { pathname } = useLocation()
  const [showWallets, setShowWallets] = useState(false)
  const [visibleWithdraw, setVisibleWithdraw] = useState(false)
  const [visibleTransfer, setVisibleTransfer] = useState(false)
  const [visibleMenu, setVisibleMenu] = useState(false)

  const { connect, connectors, error, isLoading, pendingConnector } =
    useConnect()
  const { address, isConnected } = useAccount()
  const { disconnect } = useDisconnect()

  let activeItem = navigation.find((x) => pathname === x.url)

  if (!activeItem) activeItem = navigation[1]

  return (
    <>
      <div className={cn(className, styles.wallet)}>
        <div className={styles.sidebar}>
          <div
            className={cn(styles.brand, {
              [styles.separator]: true,
            })}
          >
            <h4 className={cn('h4', styles.title)}>T1Bets.gg</h4>
            <div className={styles.separator}></div>
          </div>
          <div className={cn(styles.group, { [styles.active]: visibleMenu })}>
            <div
              className={styles.top}
              onClick={() => setVisibleMenu(!visibleMenu)}
            >
              <div
                className={styles.bg}
                style={{ backgroundColor: activeItem.color }}
              ></div>
              {activeItem.title}
            </div>
            <div className={styles.menu}>
              {navigation.map((item) =>
                item.external ? (
                  <a
                    className={cn('cursor-pointer', styles.item, {
                      [styles.separator]: true,
                    })}
                    href={item.url}
                    target="_blank"
                    key={item.url}
                  >
                    {item.color && (
                      <div
                        className={styles.bg}
                        style={{ backgroundColor: item.color }}
                      ></div>
                    )}
                    {item.icon && <Icon name={item.icon} size="20" />}
                    {item.title}
                  </a>
                ) : item.url ? (
                  <NavLink
                    className={cn(styles.item, {
                      [styles.separator]: true,
                      [styles.active]:
                        pathname.includes('match') &&
                        item.category === category,
                    })}
                    activeClassName={styles.active}
                    to={item.url}
                    key={item.url}
                    exact
                  >
                    {item.color && (
                      <div
                        className={styles.bg}
                        style={{ backgroundColor: item.color }}
                      ></div>
                    )}
                    {item.icon && <Icon name={item.icon} size="20" />}
                    {item.title}
                  </NavLink>
                ) : (
                  <button
                    className={cn(styles.item, {
                      [styles.separator]: item.separator,
                    })}
                    key={item.url}
                    onClick={() => setVisibleTransfer(!visibleTransfer)}
                  >
                    {item.icon && <Icon name={item.icon} size="20" />}
                    {item.title}
                  </button>
                )
              )}
            </div>
          </div>
          <div className={styles.btns}>
            <Modal visible={showWallets} onClose={() => setShowWallets(false)}>
              <div className="grid gap-2 mt-16">
                {connectors.map((connector) => (
                  <button
                    className={cn('button-stroke button-small', styles.button)}
                    disabled={!connector.ready}
                    key={connector.id}
                    onClick={() => connect({ connector })}
                  >
                    {connector.name}
                    {!connector.ready && ' (unsupported)'}
                    {isLoading &&
                      connector.id === pendingConnector?.id &&
                      ' (connecting)'}
                  </button>
                ))}
              </div>

              {error && <div>{error.message}</div>}
            </Modal>

            {isConnected ? (
              <button
                className={cn('button-stroke button-small', styles.button)}
                onClick={() => disconnect()}
              >
                Disconnect
              </button>
            ) : (
              <button
                className={cn('button-stroke button-small', styles.button)}
                onClick={() => setShowWallets(true)}
              >
                Connect Wallet
              </button>
            )}

            {/* <Link
              className={cn('button button-small', styles.button)}
              to="/deposit-fiat"
            >
              Deposit
            </Link>
            <button
              className={cn('button-stroke button-small', styles.button)}
              onClick={() => setVisibleWithdraw(!visibleWithdraw)}
            >
              Withdraw
            </button>
            <button
              className={cn('button-stroke button-small', styles.button)}
              onClick={() => setVisibleTransfer(!visibleTransfer)}
            >
              Transfer
            </button> */}
          </div>
        </div>
        <div className={styles.wrapper}>{children}</div>
      </div>
      <Modal
        visible={visibleWithdraw}
        onClose={() => setVisibleWithdraw(false)}
      >
        <Withdraw />
      </Modal>
      <Modal
        visible={visibleTransfer}
        onClose={() => setVisibleTransfer(false)}
      >
        <Transfer />
      </Modal>
    </>
  )
}

export default Wallet
