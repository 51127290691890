import React, { useEffect, useState } from 'react'
import cn from 'classnames'
import { useProvider, useSigner, useAccount, useBlockNumber } from 'wagmi'
import styles from './Main.module.sass'
import {
  approve,
  claim,
  getAccountInfo,
  getData,
  stake,
  unstake,
} from '../../../utils/web3'
import TextInput from '../../../components/TextInput'
import { utils } from 'ethers'

const Main = () => {
  const provider = useProvider()
  const { data: signer } = useSigner()

  const { address } = useAccount()
  const [state, setState] = useState({
    totalReserve: utils.parseEther('0'),
    totalUtilization: utils.parseEther('0'),
    totalStaked: utils.parseEther('0'),
    accountBalance: utils.parseEther('0'),
    allowance: utils.parseEther('0'),
    stakedBalance: utils.parseEther('0'),
    rewardsEarned: utils.parseEther('0'),
  })
  const [stakeAmount, setStakeAmount] = useState(0)
  const [unstakeAmount, setUnstakeAmount] = useState(0)
  const { data } = useBlockNumber({ watch: true })

  useEffect(() => {
    async function updateData() {
      try {
        const data = await getData(provider)
        setState({ ...state, ...data })

        if (address) {
          const accountInfo = await getAccountInfo(provider, address)
          setState({ ...data, ...accountInfo })
        }
      } catch (error) {
        console.log(error)
      }
    }

    updateData()
  }, [address, data])

  return (
    <div className={styles.main}>
      <h4 className={cn('h4', styles.title)}>Dashboard</h4>

      <div className={styles.list}>
        <div className={styles.item}>
          <div className={styles.info}>Reserve</div>
          <div className={styles.currency}>
            <div className={styles.number}>
              {Number(utils.formatEther(state.totalReserve)).toLocaleString(
                'en-US',
                { minimumFractionDigits: 2, maximumFractionDigits: 2 }
              )}
            </div>
            <div className={cn('category-gray', styles.category)}>T</div>
          </div>
          {/* <div className={styles.price}>$10,098.36</div> */}
        </div>

        <div className={styles.item}>
          <div className={styles.info}>Utilization</div>
          <div className={styles.currency}>
            <div className={styles.number}>
              {Number(utils.formatEther(state.totalUtilization)).toLocaleString(
                'en-US',
                { minimumFractionDigits: 2, maximumFractionDigits: 2 }
              )}
            </div>
            <div className={cn('category-gray', styles.category)}>T</div>
          </div>
          {/* <div className={styles.price}>55%</div> */}
        </div>

        <div className={styles.item}>
          <div className={styles.info}>Total Staked</div>
          <div className={styles.currency}>
            <div className={styles.number}>
              {Number(utils.formatEther(state.totalStaked)).toLocaleString(
                'en-US',
                { minimumFractionDigits: 2, maximumFractionDigits: 2 }
              )}
            </div>
            <div className={cn('category-gray', styles.category)}>T</div>
          </div>
          {/* <div className={styles.price}>$10,098.36</div> */}
        </div>

        {/* <div className={styles.item}>
          <div className={styles.info}>APR</div>
          <div className={styles.currency}>
            <div className={styles.number}>1,234</div>
            <div className={cn('category-gray', styles.category)}>%</div>
          </div>
          <div className={styles.price}>$10,098.36</div>
        </div> */}
        {/* <div className={cn(styles.item, styles.flex)}>
          <div className={styles.chart}>
            <img src="/images/content/chart.svg" alt="Chart" />
          </div>
          <div className={styles.details}>
            <div className={styles.info}>Margin level</div>
            <div className={styles.currency}>
              <div className={styles.number}>999.00</div>
              <div className={cn('category-green', styles.category)}>
                low risk
              </div>
            </div>
          </div>
        </div>
        <div className={styles.item}>
          <div className={styles.stage}>Account Equity</div>
          <div className={styles.currency}>
            <div className={styles.number}>0.00000000</div>
            <div className={cn('category-gray', styles.category)}>BTC</div>
          </div>
        </div> */}
      </div>

      <h4 className={cn('h4', styles.title)}>Account</h4>

      <div className={styles.list}>
        <div className={styles.item}>
          <div className={styles.info}>Rewards</div>
          <div className={styles.currency}>
            <div className={styles.number}>
              {Number(utils.formatEther(state.rewardsEarned)).toLocaleString(
                'en-US',
                { minimumFractionDigits: 2, maximumFractionDigits: 2 }
              )}
            </div>
            <div className={cn('category-gray', styles.category)}>T</div>
          </div>
          {/* <div className={styles.price}>$234,444.00</div> */}
        </div>

        {/* <div className={styles.item}>
          <div className={styles.info}>Total Wagered</div>
          <div className={styles.currency}>
            <div className={styles.number}>600,000</div>
            <div className={cn('category-gray', styles.category)}>T</div>
          </div>
          <div className={styles.price}>$234,444.00</div>
        </div> */}

        <div className={styles.item}>
          <div className={styles.info}>Staked</div>
          <div className={styles.currency}>
            <div className={styles.number}>
              {Number(utils.formatEther(state.stakedBalance)).toLocaleString(
                'en-US',
                { minimumFractionDigits: 2, maximumFractionDigits: 2 }
              )}
            </div>
            <div className={cn('category-gray', styles.category)}>T</div>
          </div>
          {/* <div className={styles.price}>100%</div> */}
        </div>

        <div className={styles.item}>
          <div className={styles.info}>Balance</div>
          <div className={styles.currency}>
            <div className={styles.number}>
              {Number(utils.formatEther(state.accountBalance)).toLocaleString(
                'en-US',
                { minimumFractionDigits: 2, maximumFractionDigits: 2 }
              )}
            </div>
            <div className={cn('category-gray', styles.category)}>T</div>
          </div>
          {/* <div className={styles.price}>$10,098.36</div> */}
        </div>
      </div>
      <div className="grid md:grid-cols-2 gap-4 mt-8">
        <button
          className={cn('button rounded-sm mt-4 w-full')}
          onClick={() => claim(signer)}
          disabled={state.rewardsEarned.isZero()}
        >
          Claim rewards
        </button>
      </div>
      <div className="grid md:grid-cols-2 gap-4 mt-8">
        <div className="">
          <div className={styles.box}>
            <TextInput
              label="Stake amount"
              type="text"
              placeholder="100.00 T"
              value={stakeAmount ? stakeAmount : ''}
              onChange={(e) => {
                setStakeAmount(e.target.value)
              }}
              required
            />
            <button
              className={cn('button-stroke button-small', styles.button)}
              onClick={() => {
                setStakeAmount(utils.formatEther(state.accountBalance))
              }}
            >
              MAX
            </button>
          </div>

          {state.allowance.gt(0) ? (
            <button
              className={cn('button rounded-sm mt-4 w-full')}
              onClick={() => stake(signer, utils.parseEther(stakeAmount))}
              disabled={!stakeAmount}
            >
              Stake
            </button>
          ) : (
            <button
              className={cn('button rounded-sm mt-4 w-full')}
              onClick={() => approve(signer)}
            >
              Approve
            </button>
          )}
        </div>

        <div className="">
          <div className={styles.box}>
            <TextInput
              label="Unstake amount"
              type="text"
              placeholder="100.00 T"
              value={unstakeAmount ? unstakeAmount : ''}
              onChange={(e) => {
                setUnstakeAmount(e.target.value)
              }}
              required
            />
            <button
              className={cn('button-stroke button-small', styles.button)}
              onClick={() => {
                setUnstakeAmount(utils.formatEther(state.stakedBalance))
              }}
            >
              MAX
            </button>
          </div>

          <button
            className={cn('button rounded-sm mt-4 w-full')}
            onClick={() => unstake(signer, utils.parseEther(unstakeAmount))}
            disabled={!unstakeAmount}
          >
            Unstake
          </button>
        </div>
      </div>
    </div>
  )
}

export default Main
