import React, { useState, useEffect } from 'react'
import { useProvider, useSigner, useAccount, useBlockNumber } from 'wagmi'
import { utils } from 'ethers'
import cn from 'classnames'
import * as ToggleGroup from '@radix-ui/react-toggle-group'
import styles from './WalletOverview.module.sass'
import Wallet from '../../components/Wallet'
import TextInput from '../../components/TextInput'
import Funds from './Funds'
import MyBets from './MyBets'
import { approve, createBet, getAccountInfo, getData } from '../../utils/web3'
import { MATCHES } from '../../utils/constants'

// import Icon from '../../components/Icon'
// import Dropdown from '../../components/Dropdown'
// import AccountBalances from './AccountBalances'
// import AssetBalances from './AssetBalances'
// import Integrations from './Integrations'

// const optionsCurrency = ['USD', 'EUR', 'RUB']

function addZero(i) {
  if (i < 10) {
    i = '0' + i
  }
  return i
}

export const Match = ({ id }) => {
  // const [search, setSearch] = useState('')
  // const [currency, setCurrency] = useState(optionsCurrency[0])

  const provider = useProvider()
  const { data: signer } = useSigner()
  const { address } = useAccount()
  const [state, setState] = useState({
    totalReserve: utils.parseEther('0'),
    totalUtilization: utils.parseEther('0'),
    totalStaked: utils.parseEther('0'),
    accountBalance: utils.parseEther('0'),
    allowance: utils.parseEther('0'),
    stakedBalance: utils.parseEther('0'),
    rewardsEarned: utils.parseEther('0'),
    myCreatedBets: [],
    recentBets: [],
    matchInfo: {
      outcome: 0,
    },
  })
  const [betAmount, setBetAmount] = useState(0)
  const [outcome, setOutcome] = useState(0)
  const { data: currentBlock } = useBlockNumber({ watch: true })

  useEffect(() => {
    async function updateData() {
      try {
        const data = await getData(provider, id, currentBlock)
        setState({ ...state, ...data })

        if (address) {
          const accountInfo = await getAccountInfo(provider, address, id)
          setState({ ...data, ...accountInfo })
        }
      } catch (error) {
        console.log(error)
      }
    }

    updateData()
  }, [address, currentBlock])

  const currentMatch = MATCHES[id]
  const exceededDate = new Date().getTime() / 1000 > currentMatch.timestamp

  return (
    <Wallet category={currentMatch.category}>
      <div className={styles.top}>
        <div>
          <h2
            className={cn(
              'text-2xl md:text-5xl font-semibold md:my-8',
              'text-center'
            )}
          >
            {currentMatch.description}
          </h2>

          <div className="max-w-2xl mx-auto my-8 md:my-16">
            <div className="flex justify-between">
              <div className="flex flex-col items-center gap-4">
                <div className={styles.flag}>
                  <img
                    src={currentMatch.data[0].image}
                    alt={currentMatch.data[0].name}
                  />
                </div>
                <p className="md:text-xl font-semibold whitespace-nowrap overflow-hidden">
                  {currentMatch.data[0].name}
                </p>
              </div>
              <div className="flex flex-col justify-center items-center">
                <p className="md:text-md text-xs font-semibold text-gray-600">
                  {new Date(currentMatch.timestamp * 1000)
                    .toDateString()
                    .slice(0, -5)}
                  {/* 18th December */}
                </p>
                <p className="md:text-2xl text-lg font-semibold">
                  {`${addZero(
                    new Date(currentMatch.timestamp * 1000).getHours()
                  )}:${addZero(
                    new Date(currentMatch.timestamp * 1000).getMinutes()
                  )}`}
                </p>
              </div>
              <div className="flex flex-col items-center gap-4">
                <div className={styles.flag}>
                  <img
                    src={currentMatch.data[1].image}
                    alt={currentMatch.data[1].name}
                  />
                </div>
                <p className="md:text-xl font-semibold whitespace-nowrap overflow-hidden">
                  {currentMatch.data[1].name}
                </p>
              </div>
            </div>
          </div>

          <ToggleGroup.Root
            className={cn(
              'grid gap-4',
              currentMatch.odds[2] ? 'md:grid-cols-3' : 'md:grid-cols-2'
            )}
            type="single"
            defaultValue={0}
            onValueChange={(value) => setOutcome(value)}
          >
            <ToggleGroup.Item
              disabled={exceededDate && currentMatch.result !== 0}
              className={`button-stroke rounded grid gap-2 ${
                currentMatch.result === 0 ? 'button-green' : ''
              }`}
              value={1}
            >
              <p className="text-gray-500 whitespace-nowrap overflow-hidden">
                {currentMatch.data[0].name}
              </p>
              <p>{currentMatch.odds[0].toFixed(2)}</p>
            </ToggleGroup.Item>
            {!!currentMatch.odds[2] && (
              <ToggleGroup.Item
                className={`button-stroke rounded grid gap-2 ${
                  currentMatch.result === 2 ? 'button-green' : ''
                }`}
                value={3}
                disabled={exceededDate && currentMatch.result !== 2}
                onClick={() => setOutcome(3)}
              >
                <p className="text-gray-500">Draw</p>
                <p>{currentMatch.odds[2].toFixed(2)}</p>
              </ToggleGroup.Item>
            )}
            <ToggleGroup.Item
              className={`button-stroke rounded grid gap-2 ${
                currentMatch.result === 1 ? 'button-green' : ''
              }`}
              value={2}
              disabled={exceededDate && currentMatch.result !== 1}
              onClick={() => setOutcome(2)}
            >
              <p className="text-gray-500 whitespace-nowrap overflow-hidden">
                {currentMatch.data[1].name}
              </p>{' '}
              <p>{currentMatch.odds[1].toFixed(2)}</p>
            </ToggleGroup.Item>
          </ToggleGroup.Root>

          <div className="grid md:grid-cols-2 gap-4 mt-8">
            <div className="">
              {!exceededDate ? (
                <>
                  <div className={styles.box}>
                    <TextInput
                      label="Bet amount"
                      type="text"
                      placeholder={`Minimum ${currentMatch.minBetAmount.toFixed(
                        2
                      )} T`}
                      value={betAmount ? betAmount : ''}
                      onChange={(e) => {
                        setBetAmount(e.target.value)
                      }}
                      required
                    />
                    <button
                      className={cn(
                        'button-stroke button-small',
                        styles.button
                      )}
                      onClick={() =>
                        setBetAmount(currentMatch.maxBetAmount + '')
                      }
                    >
                      MAX
                    </button>
                  </div>

                  {state.allowance.gt(0) ? (
                    <button
                      className={cn('button rounded-sm mt-4 w-full')}
                      onClick={() =>
                        createBet(
                          signer,
                          currentMatch.index,
                          outcome,
                          utils.parseEther(betAmount)
                        )
                      }
                      disabled={!outcome || !betAmount}
                    >
                      Confirm
                    </button>
                  ) : (
                    <button
                      className={cn('button rounded-sm mt-4 w-full')}
                      onClick={() => approve(signer)}
                    >
                      Approve
                    </button>
                  )}
                </>
              ) : null}

              <MyBets bets={state.myCreatedBets} matchInfo={state.matchInfo} />
            </div>

            <Funds bets={state.recentBets} />
          </div>

          {/* 
          <div className={styles.wrap}>
            <form
              className={styles.form}
              action=""
              onSubmit={() => handleSubmit()}
            >
              <input
                className={styles.input}
                type="text"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                name="search"
                placeholder="Search coin"
                required
              />
              <button className={styles.result}>
                <Icon name="search" size="20" />
              </button>
            </form>
            <Dropdown
              className={styles.dropdown}
              classDropdownHead={styles.dropdownHead}
              value={currency}
              setValue={setCurrency}
              options={optionsCurrency}
            />
            <button className={cn('button-black button-small', styles.button)}>
              Show balance
            </button>
          </div> */}
        </div>

        {/* <div className={styles.details}>
          <div className={styles.info}>Total balance</div>
          <div className={styles.currency}>
            <div className={styles.number}>0.27894652</div>
            <div className={cn('category-gray', styles.category)}>T</div>
          </div>
          <div className={styles.price}>$10,098.36</div>
        </div> */}
      </div>

      <div className={styles.list}>
        {/* <div className={styles.item}>
          <div className={styles.head}>Upcoming Matches</div>
          <div className={styles.body}>
            <AccountBalances />
          </div>
        </div>

        <div className={styles.item}>
          <div className={styles.head}>Live Matches</div>
          <div className={styles.body}>
            <AccountBalances />
          </div>
        </div>

        <div className={styles.item}>
          <div className={styles.head}>Finished Matches</div>
          <div className={styles.body}>
            <AccountBalances />
          </div>
        </div> */}

        {/* <div className={styles.item}>
          <div className={styles.head}>Asset Balances</div>
          <div className={styles.body}>
            <AssetBalances />
          </div>
        </div> */}

        {/* <div className={styles.item}>
          <div className={styles.head}>Integrations</div>
          <div className={styles.body}>
            <Integrations />
          </div>
        </div> */}
      </div>
    </Wallet>
  )
}
