import React, { useState, useEffect } from 'react'
import { useProvider, useSigner, useAccount, useBlockNumber } from 'wagmi'
import { utils } from 'ethers'
import cn from 'classnames'
import styles from './WalletOverview.module.sass'
import Wallet from '../../components/Wallet'
// import TextInput from '../../components/TextInput'
// import Funds from './Funds'
// import MyBets from './MyBets'
import {
  approve,
  createBet,
  createGame,
  getAccountInfo,
  getData,
} from '../../utils/web3'
import { MATCHES } from '../../utils/constants'
import { NavLink } from 'react-router-dom'
import moment from 'moment'
import { parseEther } from 'ethers/lib/utils.js'

// import Icon from '../../components/Icon'
// import Dropdown from '../../components/Dropdown'
// import AccountBalances from './AccountBalances'
// import AssetBalances from './AssetBalances'
// import Integrations from './Integrations'

// const optionsCurrency = ['USD', 'EUR', 'RUB']

function addZero(i) {
  if (i < 10) {
    i = '0' + i
  }
  return i
}

const CustomNavLink = ({ children, isNavLink, currentMatch }) => {
  return isNavLink ? (
    <NavLink key={currentMatch.index} to={`/match/${currentMatch.index}`}>
      {children}
    </NavLink>
  ) : (
    <div>{children}</div>
  )
}

export const Matches = ({ category }) => {
  // const [search, setSearch] = useState('')
  // const [currency, setCurrency] = useState(optionsCurrency[0])

  const provider = useProvider()
  const { data: signer } = useSigner()
  const { address } = useAccount()
  const [state, setState] = useState({
    totalReserve: utils.parseEther('0'),
    totalUtilization: utils.parseEther('0'),
    totalStaked: utils.parseEther('0'),
    accountBalance: utils.parseEther('0'),
    allowance: utils.parseEther('0'),
    stakedBalance: utils.parseEther('0'),
    rewardsEarned: utils.parseEther('0'),
    myCreatedBets: [],
    recentBets: [],
    matchInfo: {
      outcome: 0,
    },
    matches: [],
    settledMatches: [],
  })
  const [betAmount, setBetAmount] = useState(0)
  const [outcome, setOutcome] = useState(0)
  const { data } = useBlockNumber({ watch: true })

  useEffect(() => {
    async function updateData() {
      try {
        const data = await getData(provider)
        setState({ ...state, ...data })

        if (address) {
          const accountInfo = await getAccountInfo(provider, address)
          setState({ ...data, ...accountInfo })
        }
      } catch (error) {
        console.log(error)
      }
    }

    updateData()
  }, [address, data])

  const matches =
    category === 'admin'
      ? MATCHES
      : MATCHES.filter((match) => match.category === category)

  const upcomingMatches = matches.filter(
    (match) => match.timestamp * 1000 > new Date().getTime()
  )
  // const finishedMatches = matches.filter((match) => {
  //   return state.settledMatches.find((settledMatch) =>
  //     settledMatch.args.index.eq(match.index)
  //   )
  //     ? true
  //     : false
  // })

  const finishedMatches = matches.filter(
    (match) => match.timestamp * 1000 <= new Date().getTime()
  )

  return (
    <Wallet category={category}>
      <h2 className="text-lg font-semibold m-4">Upcoming Matches</h2>
      <div className={cn('grid md:grid-cols-2 gap-1')}>
        {upcomingMatches.map((currentMatch) => {
          return (
            <CustomNavLink
              isNavLink={category !== 'admin'}
              currentMatch={currentMatch}
            >
              <div className={styles.top}>
                <p className="text-lg font-semibold text-gray-500">
                  {currentMatch.description}
                </p>
                <div className="flex flex-col">
                  <p className="md:text-md text-xs font-semibold text-gray-600">
                    {`${new Date(currentMatch.timestamp * 1000)
                      .toDateString()
                      .slice(0, -5)}, ${addZero(
                      new Date(currentMatch.timestamp * 1000).getHours()
                    )}:${addZero(
                      new Date(currentMatch.timestamp * 1000).getMinutes()
                    )}`}
                    {/* 18th December */}
                  </p>
                </div>

                <div className="">
                  <div className="flex flex-col gap-4 my-4">
                    <div className="flex items-center gap-4">
                      <div className={styles.flag}>
                        <img
                          src={currentMatch.data[0].image}
                          alt={currentMatch.data[0].name}
                        />
                      </div>
                      <p className="md:text-lg font-semibold">
                        {currentMatch.data[0].name}
                      </p>
                    </div>

                    <div className="flex items-center gap-4">
                      <div className={styles.flag}>
                        <img
                          src={currentMatch.data[1].image}
                          alt={currentMatch.data[1].name}
                        />
                      </div>
                      <p className="md:text-lg font-semibold">
                        {currentMatch.data[1].name}
                      </p>
                    </div>
                  </div>
                </div>

                <div
                  className={cn(
                    'grid gap-2',
                    currentMatch.odds[2] ? 'grid-cols-3' : 'grid-cols-2'
                  )}
                >
                  <div className="button-stroke rounded grid gap-2">
                    <p className="text-gray-500 whitespace-nowrap overflow-hidden">
                      {currentMatch.data[0].name}
                    </p>
                    <p>{currentMatch.odds[0].toFixed(2)}</p>
                  </div>
                  {!!currentMatch.odds[2] && (
                    <div className="button-stroke rounded grid gap-2">
                      <p className="text-gray-500">Draw</p>
                      <p>{currentMatch.odds[2].toFixed(2)}</p>
                    </div>
                  )}
                  <div className="button-stroke rounded grid gap-2">
                    <p className="text-gray-500 whitespace-nowrap overflow-hidden">
                      {currentMatch.data[1].name}
                    </p>{' '}
                    <p>{currentMatch.odds[1].toFixed(2)}</p>
                  </div>
                </div>
                {category === 'admin' && (
                  <button
                    className="button rounded grid gap-2 mt-4"
                    onClick={() =>
                      createGame(
                        signer,
                        currentMatch.timestamp,
                        currentMatch.odds.map((odd) => parseEther(odd + '')),
                        parseEther(currentMatch.minBetAmount + ''),
                        parseEther(currentMatch.maxBetAmount + '')
                      )
                    }
                  >
                    Create Game
                  </button>
                )}
              </div>
            </CustomNavLink>
          )
        })}
      </div>

      {!!finishedMatches.length && (
        <h2 className="text-lg font-semibold mt-8 mb-4 ml-4">
          Finished Matches
        </h2>
      )}
      <div className={cn('grid md:grid-cols-2 gap-1')}>
        {finishedMatches.map((currentMatch) => {
          return (
            <CustomNavLink
              isNavLink={category !== 'admin'}
              currentMatch={currentMatch}
            >
              <div className={styles.top}>
                <p className="text-lg font-semibold text-gray-500">
                  {currentMatch.description}
                </p>
                <div className="flex flex-col">
                  <p className="md:text-md text-xs font-semibold text-gray-600">
                    {`${new Date(currentMatch.timestamp * 1000)
                      .toDateString()
                      .slice(0, -5)}, ${addZero(
                      new Date(currentMatch.timestamp * 1000).getHours()
                    )}:${addZero(
                      new Date(currentMatch.timestamp * 1000).getMinutes()
                    )}`}
                    {/* 18th December */}
                  </p>
                </div>

                <div className="">
                  <div className="flex flex-col gap-4 my-4">
                    <div className="flex items-center gap-4">
                      <div className={styles.flag}>
                        <img
                          src={currentMatch.data[0].image}
                          alt={currentMatch.data[0].name}
                        />
                      </div>
                      <p className="md:text-lg font-semibold">
                        {currentMatch.data[0].name}
                      </p>
                    </div>

                    <div className="flex items-center gap-4">
                      <div className={styles.flag}>
                        <img
                          src={currentMatch.data[1].image}
                          alt={currentMatch.data[1].name}
                        />
                      </div>
                      <p className="md:text-lg font-semibold">
                        {currentMatch.data[1].name}
                      </p>
                    </div>
                  </div>
                </div>

                <div
                  className={cn(
                    'grid gap-2',
                    currentMatch.odds[2] ? 'grid-cols-3' : 'grid-cols-2'
                  )}
                >
                  <button
                    disabled={currentMatch.result !== 0}
                    className={`button-stroke rounded grid gap-2 ${
                      currentMatch.result === 0 ? 'button-green' : ''
                    }`}
                  >
                    <p className="text-gray-500 whitespace-nowrap overflow-hidden">
                      {currentMatch.data[0].name}
                    </p>
                    <p>{currentMatch.odds[0].toFixed(2)}</p>
                  </button>
                  {!!currentMatch.odds[2] && (
                    <button
                      disabled={currentMatch.result !== 2}
                      className={`button-stroke rounded grid gap-2  ${
                        currentMatch.result === 2 ? 'button-green' : ''
                      }`}
                    >
                      <p className="text-gray-500">Draw</p>
                      <p>{currentMatch.odds[2].toFixed(2)}</p>
                    </button>
                  )}
                  <button
                    disabled={currentMatch.result !== 1}
                    className={`button-stroke rounded grid gap-2  ${
                      currentMatch.result === 1 ? 'button-green' : ''
                    }`}
                  >
                    <p className="text-gray-500 whitespace-nowrap overflow-hidden">
                      {currentMatch.data[1].name}
                    </p>{' '}
                    <p>{currentMatch.odds[1].toFixed(2)}</p>
                  </button>
                </div>
                {category === 'admin' && (
                  <button
                    className="button rounded grid gap-2 mt-4"
                    onClick={() =>
                      createGame(
                        signer,
                        currentMatch.index,
                        currentMatch.result
                      )
                    }
                  >
                    SettleGame
                  </button>
                )}
              </div>
            </CustomNavLink>
          )
        })}
      </div>
      {/* <div className="grid gap-4 mt-8">
        <Funds bets={state.recentBets} />
      </div> */}
    </Wallet>
  )
}
