import React, { useEffect } from 'react'
import { withRouter, useLocation } from 'react-router-dom'
import { clearAllBodyScrollLocks } from 'body-scroll-lock'
import styles from './Page.module.sass'
import Header from '../Header'
import Footer from '../Footer'
import Theme from '../Theme'
import cn from 'classnames'

import { WagmiConfig, createClient, configureChains } from 'wagmi'
import { mainnet, foundry } from '@wagmi/chains'

// import { alchemyProvider } from 'wagmi/providers/alchemy'
import { jsonRpcProvider } from 'wagmi/providers/jsonRpc'
import { publicProvider } from 'wagmi/providers/public'

import { CoinbaseWalletConnector } from 'wagmi/connectors/coinbaseWallet'
import { InjectedConnector } from 'wagmi/connectors/injected'
import { MetaMaskConnector } from 'wagmi/connectors/metaMask'
import { WalletConnectConnector } from 'wagmi/connectors/walletConnect'

const { chains, provider, webSocketProvider } = configureChains(
  [mainnet],
  [
    // publicProvider(),
    jsonRpcProvider({
      rpc: () => ({
        http: `https://eth.llamarpc.com`,
      }),
    }),
  ]
)

const client = createClient({
  autoConnect: true,
  connectors: [
    new MetaMaskConnector({ chains }),
    new CoinbaseWalletConnector({
      chains,
      options: {
        appName: 'T1Bets',
      },
    }),
    new WalletConnectConnector({
      chains,
      options: {
        qrcode: true,
      },
    }),
    new InjectedConnector({
      chains,
      options: {
        name: 'Injected',
        shimDisconnect: true,
      },
    }),
  ],
  provider,
  webSocketProvider,
})

const Page = ({ headerHide, children, footerHide, headerWide }) => {
  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
    clearAllBodyScrollLocks()
  }, [pathname])

  return (
    <WagmiConfig client={client}>
      <div className={styles.page}>
        {/* {!headerHide && <Header headerWide={headerWide} />} */}
        <div className={styles.inner}>{children}</div>
        {/* {!footerHide && <Footer />} */}
        <Theme className={cn(styles.theme)} icon />
      </div>
    </WagmiConfig>
  )
}

export default withRouter(Page)
