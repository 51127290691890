import { getProvider, getAccount } from '@wagmi/core'
import { ERC20_ABI, T1BETS_ABI } from './abis'
import { MATCHES, T1BETS_ADDRESS, T_ADDRESS } from './constants'

// import BigNumber from 'bignumber.js'
// import { UniswapV2Router02 } from '../constants/contracts'
// import { ESD, ESDS, UNI, USDC } from '../constants/tokens'
// import { POOL, POOL_EXIT_LOCKUP_EPOCHS } from '../constants/values'
import { Contract, utils, constants } from 'ethers'
// import { formatBN, toBaseUnitBN, toTokenUnitsBN } from './number'
// import { formatEther, parseEther } from 'ethers/lib/utils'

export const getData = async (
  provider,
  gameId = MATCHES.length - 1,
  currentBlock
) => {
  const betContract = new Contract(T1BETS_ADDRESS, T1BETS_ABI, provider)

  const [
    totalStaked,
    totalReserve,
    totalUtilization,
    recentBets,
    matchInfo,
    matches,
    settledMatches,
  ] = await Promise.all([
    betContract.totalStaked(),
    betContract.totalReserve(),
    betContract.totalUtilization(),
    betContract.queryFilter(
      betContract.filters.CreateBet(),
      16211856,
      currentBlock
    ),
    betContract.games(gameId),
    betContract.queryFilter(
      betContract.filters.CreateGame(),
      16211856,
      currentBlock
    ),
    betContract.queryFilter(
      betContract.filters.SettleGame(),
      16211856,
      currentBlock
    ),
  ])

  return {
    totalStaked,
    totalReserve,
    totalUtilization,
    recentBets: recentBets.filter(({ args }) => args.gameId.eq(gameId)),
    matchInfo,
    matches,
    settledMatches,
  }
}

export const getAccountInfo = async (
  provider,
  account,
  gameId = MATCHES.length - 1
  // currentBlock
) => {
  const tokenContract = new Contract(T_ADDRESS, ERC20_ABI, provider)
  const betContract = new Contract(T1BETS_ADDRESS, T1BETS_ABI, provider)

  const [
    accountBalance,
    allowance,
    userInfo,
    rewardsEarned,
    myCreatedBets,
    // mySettledBets,
  ] = await Promise.all([
    tokenContract.balanceOf(account),
    tokenContract.allowance(account, T1BETS_ADDRESS),
    betContract.userInfo(account),
    betContract.calculateRewardsEarned(account),
    betContract.queryFilter(betContract.filters.CreateBet(account)),
    // betContract.queryFilter(betContract.filters.SettleBet(account), 16211856),
  ])

  return {
    allowance,
    accountBalance,
    stakedBalance: userInfo.staked,
    rewardsEarned,
    myCreatedBets: myCreatedBets.filter(({ args }) => args.gameId.eq(gameId)),
  }
}

export const approve = async (
  signer,
  tokenAddr = T_ADDRESS,
  spender = T1BETS_ADDRESS,
  amount = constants.MaxUint256
) => {
  const contract = new Contract(tokenAddr, ERC20_ABI, signer)
  try {
    const tx = await contract.approve(spender, amount)
  } catch (error) {
    console.log(error)
  }
}

export const stake = async (signer, amount) => {
  const contract = new Contract(T1BETS_ADDRESS, T1BETS_ABI, signer)
  try {
    const tx = await contract.stake(amount)
  } catch (error) {
    console.log(error)
  }
}

export const unstake = async (signer, amount) => {
  const contract = new Contract(T1BETS_ADDRESS, T1BETS_ABI, signer)
  try {
    const tx = await contract.unstake(amount)
  } catch (error) {
    console.log(error)
  }
}

export const claim = async (signer) => {
  const contract = new Contract(T1BETS_ADDRESS, T1BETS_ABI, signer)
  try {
    const tx = await contract.claim()
  } catch (error) {
    console.log(error)
  }
}

export const createBet = async (signer, gameId, outcome, amount) => {
  const contract = new Contract(T1BETS_ADDRESS, T1BETS_ABI, signer)
  try {
    const tx = await contract.createBet(gameId, outcome, amount)
  } catch (error) {
    console.log(error)
  }
}

export const settleBet = async (signer, winner, betId) => {
  const contract = new Contract(T1BETS_ADDRESS, T1BETS_ABI, signer)
  try {
    const tx = await contract.settleBet(winner, betId)
  } catch (error) {
    console.log(error)
  }
}

export const createGame = async (signer, deadline, odds, minBet, maxBet) => {
  const contract = new Contract(T1BETS_ADDRESS, T1BETS_ABI, signer)
  try {
    const tx = await contract.createGame(deadline, odds, minBet, maxBet)
  } catch (error) {
    console.log(error)
  }
}

export const settleGame = async (signer, gameId, outcome) => {
  const contract = new Contract(T1BETS_ADDRESS, T1BETS_ABI, signer)
  try {
    const tx = await contract.settleGame(gameId, outcome)
  } catch (error) {
    console.log(error)
  }
}
